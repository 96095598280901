import TtPayCard from './TtPayCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import TtPayListUtil,{ITtPayListDataObj} from './ttPayListUtil';
import language from './ttPayLanguage'
export default defineComponent ({
    name: 'TtPayList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ITtPayListDataObj=reactive<ITtPayListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                cardFrom:'TtPayList',
                queryParam: {},
                modelComp: TtPayCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/ttPay/pageData')
            },
            otherParams:{
                customerData:[]
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtPayListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'订单收款'},{value:1,label:'信保回款'}]
                }
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'否'},{value:1,label:'是'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{

            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});